import React from 'react';
import ReactDOM from 'react-dom';
import App from './system/router/App';
import ProfileLoader from "./components/Loaders/RedirectLoader";
import reportWebVitals from './reportWebVitals';
import './system/style/main.css';

const jsx = (
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
ReactDOM.render(
    <ProfileLoader/>,
  document.getElementById('root')
);

setTimeout(
    () => {
        ReactDOM.render(
            jsx,
            document.getElementById('root')
        );
    }, 4000
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
