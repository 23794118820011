import styled from 'styled-components';
import pallette from './pallette';

export const MainContainer = styled.main`
  background: ${pallette.white};
  display: grid;
  grid-gap: 10px;
  padding-bottom: 30px;
  
  .banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    width: 100vw;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    .textMain {
      width: 40%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      h1, h3 {
        width: 35vw;
        min-width: 200px;
        font-family: ${pallette.fontFamily};
      }
      h1 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 24px;
        text-align: center;
      }
      h3 {
        font-weight: 400;
        font-size: 18px;
        text-align: justify;
      }
      @media (max-width: 1024px) {
        width: 50%;
        h1 {
          font-size: 20px;
        }
        h3 {
          font-size: 14px;
        }
      }
      @media (max-width: 768px) {
        width: 60%;
        h1 {
          font-size: 20px;
        }
        h3 {
          font-size: 14px;
        }
        h1, h3 {
          width: 90%;
          text-align: start;
        }
      }
      @media (max-width: 425px) {
        width: 100%;
        padding: 0 10px;
        h1, h3 {
          width: 90%;
        }
        h3 {
          text-align: start;
          font-size: 16px;
        }
      }
    }
    .imgIcon {
      width: 30%;
      height: 80%;
      display: flex;
      flex-direction: column;
      img {
        width: 350px;
        margin: auto;
      }
      @media (max-width: 1024px) {
        width: 40%;
        img {
          width: 230px;
        }
      }
      @media (max-width: 768px) {
        width: 40%;
        img {
          width: 150px;
        }
      }
      @media (max-width: 425px) {
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: 5px;
        right: 5px;
        img {
          width: 100%;
          margin: 0;
        }
      }
    }
    @media (max-width: 1024px) {
      
    }
    @media (max-width: 768px) {
     height: 250px;
    }
    @media (max-width: 425px) {
      height: 220px;
      position: relative;
    }
  }
  
  .twoSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    padding: 42px 0;
    .textMain {
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      h1, h3 {
        width: 35vw;
        min-width: 200px;
        font-family: ${pallette.fontFamily};
      }
      h1 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 24px;
        text-align: center;
      }
      h3 {
        font-weight: 400;
        font-size: 18px;
        text-align: left;
      }
      @media (max-width: 1024px) {
        width: 50%;
        h1 {
          font-size: 20px;
        }
        h3 {
          font-size: 14px;
        }
        h1, h3 {
          width: 90%;
        }
      }
      @media (max-width: 768px) {
        width: 60%;
        h1 {
          font-size: 18px;
        }
        h3 {
          font-size: 14px;
        }
        h1, h3 {
          width: 90%;
          text-align: start;
        }
      }
      @media (max-width: 425px) {
        width: 100%;
        padding: 0 10px;
        h1, h3 {
          width: 90%;
        }
        h3 {
          text-align: justify;
          font-size: 16px;
        }
      }
    }
    .imgIcon {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      img {
        width: 300px;
        margin: auto;
      }
      @media (max-width: 1024px) {
        width: 40%;
        img {
          width: 230px;
        }
      }
      @media (max-width: 768px) {
        width: 40%;
        img {
          width: 150px;
        }
      }
      @media (max-width: 425px) {
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: 5px;
        right: 5px;
        img {
          width: 100%;
          margin: 0;
        }
      }
    }
    @media (max-width: 1024px) {

    }
    @media (max-width: 768px) {
      min-height: 300px;
      padding: 0;
    }
    @media (max-width: 425px) {
      padding: 12px 0;
      min-height: 250px;
      position: relative;
    }
  }
`;

export default styled;
