import {
  Switch, Redirect, Route, BrowserRouter,
} from 'react-router-dom';
import React from 'react';
import Main from '../../pages/Main';
import We from '../../pages/we';
import Contact from '../../pages/Contact';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path="/main" component={Main} />
        <Route exact path="/we" component={We} />
        <Route exact path="/contact" component={Contact} />
        <Redirect to="/main" />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
