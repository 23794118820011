import React from 'react';
import MainContainer from '../../system/style/styleWe';
import BackgroundBanner from '../../access/images/backgroundBanner.png';
import ImgSpaceWe from '../../access/images/ImgSpaceWe.png';
import Pet from '../../access/images/petNova.png';
import Collaborators from '../../system/model/collaborators.json';

const We = () => (
  <MainContainer>
    <section
      style={{
        backgroundImage: `url("${BackgroundBanner}")`,
      }}
      className="banner"
    >
      <article
        className="textMain"
      >
        <h1>
          Nuestro Equipo
        </h1>
        <h3>
          Puedes tener éxito y más rápido, al ayudar a otros a tener éxito
        </h3>
      </article>
      <article className="imgIcon">
        <img alt="Icon" src={ImgSpaceWe} />
      </article>
    </section>
    <section
      id="positionCollection"
    >
      {
        Collaborators?.map(
          (collaborator) => (
            <article>
              <img src={Pet} alt="petNova" />
              <h1>
                {collaborator?.name}
              </h1>
              <h3>
                {collaborator?.position}
              </h3>
              <p>
                {collaborator?.description}
              </p>
            </article>
          ),
        )
      }

    </section>
  </MainContainer>
);

export default We;
