import styled from 'styled-components';
import pallette from './pallette';

export const NavBar = styled.nav`
  width: 100vw;
  background: ${pallette.mainColor};
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  #Logo {
    height: 40%;
    object-fit: contain;
    object-position: center;
    margin-left: 40px;
  }
  ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 60px;
    padding: 10px;
    a {
      color: ${pallette.secondColor};
      text-decoration: none;
      letter-spacing: 1.5px;
      font-size: 18px;
      font-family: ${pallette.fontFamily};
      text-transform: uppercase;
      padding: 0 10px 4px 10px;
    }
    .active {
      color: ${pallette.white};
      border-bottom: solid ${pallette.white} 1px;
    }
  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 768px) {

  }
  @media (max-width: 425px) {
    #Logo {
      display: none;
    }
    ul {
      a {
        font-size: 12px;
      }
    }
  }
`;

export const MainFooter = styled.main`
  width: 100vw;
  background: ${pallette.mainColor};
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  #boxImageLogo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 40px;
    width: 200px;
    #logo {
      width: 150px;
      object-fit: contain;
      object-position: center;
    }
    #pet {
      height: 100px;
      object-fit: contain;
      object-position: center;
      margin-left: -85px;
    }
    @media (max-width: 1024px) {
    }
    @media (max-width: 768px) {
      width: 20%;
      #logo {
        width: 70%;
      }
    }
    @media (max-width: 425px) {
      width: 40%;
      #logo { 
        width: 70%;
      }
      #pet {
        margin-left: -55px;
      }
    }
  }
  #boxNavBar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 200px;
    a {
      color: ${pallette.white};
      font-family: ${pallette.fontFamily};
      letter-spacing: 1px;
      text-decoration: none;
    }
    @media (max-width: 1024px) {

    }
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 425px) {
      display: none;
    }
  }
  #boxSocialMedia {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 200px;
    h1 {
      font-family: ${pallette.fontFamily};
      color: ${pallette.white};
      font-weight: 400;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
    article {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .boxIcon {
        width: 30px;
        background: transparent;
        border: none;
        .icon {
          color: ${pallette.white};
        }
      }
    }
    @media (max-width: 1024px) {

    }
    @media (max-width: 768px) {
      h1 {
        font-size: 16px;
      }
    }
    @media (max-width: 425px) {
     width: 50%;
      h1 {
        font-size: 16px;
      }
    }
  }
  #boxIconGalaxy {
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-right: 40px;
    @media (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 425px) {
      display: none;
    }
  }
`;

export default styled;

// @media (max-width: 1024px) {
//
// }
// @media (max-width: 768px) {
//
// }
// @media (max-width: 425px) {
//
// }
