import styled from 'styled-components';
import pallette from './pallette';

const MainContainer = styled.main`
  h1, h2, h3, p {
    font-family: ${pallette.fontFamily};
  }
  .banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    width: 100vw;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    .textMain {
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      h1, h3 {
        width: 35vw;
        min-width: 200px;
        font-family: ${pallette.fontFamily};
      }
      h1 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 24px;
        text-align: center;
      }
      h3 {
        font-weight: 400;
        font-size: 18px;
        text-align: justify;
      }
      @media (max-width: 1024px) {
        width: 50%;
        h1 {
          font-size: 20px;
        }
        h3 {
          font-size: 14px;
        }
      }
      @media (max-width: 768px) {
        width: 60%;
        h1 {
          font-size: 20px;
        }
        h3 {
          font-size: 14px;
        }
        h1, h3 {
          width: 90%;
          text-align: start;
        }
      }
      @media (max-width: 425px) {
        width: 100%;
        padding: 0 10px;
        h1, h3 {
          width: 90%;
        }
        h3 {
          text-align: start;
          font-size: 16px;
        }
      }
    }
    .imgIcon {
      width: 40%;
      height: 80%;
      display: flex;
      flex-direction: column;
      img {
        width: 350px;
        margin: auto;
      }

      @media (max-width: 1024px) {
        width: 40%;
        img {
          width: 230px;
        }
      }
      @media (max-width: 768px) {
        width: 40%;
        img {
          width: 150px;
        }
      }
      @media (max-width: 425px) {
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: 5px;
        right: 5px;
        img {
          width: 100%;
          margin: 0;
        }
      }
      
    }
    @media (max-width: 1024px) {

    }
    @media (max-width: 768px) {
      height: 250px;
    }
    @media (max-width: 425px) {
      height: 220px;
      position: relative;
    }
  }
  #positionCollection {
    width: 80%;
    height: 550px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    article {
      min-width: 280px;
      height: 90%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 10px;
      h1 {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        overflow: hidden;
      }
      h3 {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        overflow: hidden;
        padding-top: 10px ;
        padding-bottom: 10px ;
      }
      p {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-weight: 400;
        text-align: start;
      }
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-position: top;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        border: #707070 solid 0.5px;
        box-shadow: inset 3px 3px 6px rgba(255,255,255,0.16);
      }
    }
  }
`;

export default MainContainer;
