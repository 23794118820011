import React from 'react';
import { TextField, TextareaAutosize } from '@mui/material';
import { useForm } from 'react-hook-form';
import MainContainerContact from '../../system/style/styleContact';
import ImgSpaceContact from '../../access/images/ImgSpaceContact.png';

const Contact = () => {
  const {
    register, handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    const subject = `Contacto desde la Página Web mi correo es ${data?.email}`;
    const body = data?.description || 'Sin descripciòn agregada';
    window.open(`mailto:ventasnovamkt@gmail.com?subject=${subject}&body=${body}`);
  };
  return (
    <MainContainerContact>
      <article className="containerLogo">
        <img alt="Logo" src={ImgSpaceContact} />
      </article>
      <article>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Contáctanos</h1>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            {
              ...register('email', { required: true })
            }
          />
          <TextareaAutosize
            label="Descripción"
            type="text"
            variant="outlined"
            className="textInput"
            {
              ...register('description', { required: true })
            }
          />
          <button
            type="submit"
          >
            Enviar
          </button>
        </form>
      </article>
    </MainContainerContact>
  );
};

export default Contact;
