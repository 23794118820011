import React from 'react';
import { NavLink } from 'react-router-dom';
import { FacebookCircle } from 'styled-icons/boxicons-logos';
import { MainFooter } from '../system/style/styleNavBar';
import PetNova from '../access/images/petNova.png';
import LogoWhite from '../access/images/logoWhite.png';
import Galaxy from '../access/images/galaxiIcon.png';

const Footer = () => (
  <MainFooter>
    <section id="boxImageLogo">
      <img alt="Logo Blanco" src={LogoWhite} id="logo" />
      <img alt="Mascota de Nova" src={PetNova} id="pet" />
    </section>
    <section id="boxNavBar">
      <NavLink to="/main"> Inicío </NavLink>
      <NavLink to="/we"> Nosotros </NavLink>
      <NavLink to="/contact"> Contacto </NavLink>
    </section>
    <section id="boxSocialMedia">
      <h1> Encuentranos </h1>
      <article>
        <button
          className="boxIcon"
          type="button"
          onClick={() => window.open('https://www.facebook.com/NovaMKTD')}
        >
          <FacebookCircle className="icon" />
        </button>

      </article>
    </section>
    <section id="boxIconGalaxy">
      <img alt="Galaxy" src={Galaxy} />
    </section>
  </MainFooter>
);
export default Footer;
