const pallette = {
  red: '#E30C14',
  mainColor: '#082032',
  secondColor: '#334756',
  textColor: '#2C394B',
  accentColor: '#FF4C29',
  white: '#F0FFFF',
  fontFamily: 'sans-serif',
  colorInput: '#E9ECF2',
};

export default pallette;
