import styled from 'styled-components';
import pallette from './pallette';

const MainContainerContact = styled.main`
  h1, h2,p {
    font-family: ${pallette.fontFamily};
  }
  width: 100vw;
  height: calc(100vh - 260px);
  display: grid;
  grid-template-columns: 50% 50%;

  .MuiFormControl-root  {
    label {
      overflow: hidden;
    }
  }
  
  article {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerLogo {
    @media (max-width: 1024px) {

    }
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 425px) {
      display: none;
    }
    img {
      width: 450px;
      object-fit: contain;
      object-position: center;

    }
  }
  
  form {
    width: 70%;
    height: 60%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    h1 {
      text-align: center;
      margin-bottom: 30px;
    }
    .textInput {
      min-height: 100px;
      max-height: 100px;
      max-width: 100%;
      min-width: 100%;
      border-color: rgba(0, 0, 0, 0.23);
      border-radius: 5px;
    }
    button {
      width: 50%;
      margin-right: auto;
      margin-left: auto;
      border: none;
      font-size: 20px;
      padding: 10px;
      border-radius: 25px;
      background-color: ${pallette.mainColor};
      color: ${pallette.white};
      letter-spacing: 0.5px  ;
    }

    @media (max-width: 1024px) {

    }
    @media (max-width: 768px) {
      width: 100%;
      height: 80%;
    }
    @media (max-width: 425px) {
      width: 100%;
      height: 80%;
    }
    
  }


  @media (max-width: 1024px) {

  }
  @media (max-width: 768px) {
   grid-template-columns: 100%;
    height: 100vh;
  }
  @media (max-width: 425px) {
    grid-template-columns: 100%;
    height: 100vh;
  }
`;

export default MainContainerContact;
