import React from 'react';
import ContentLoader from 'react-content-loader';

function ProfileLoader() {
  return (
    <div style={{ margin: '80px 12.5%', height: '100vh' }}>
      <ContentLoader viewBox="0 0 400 160" speed={2} backgroundColor="#1E242B">
        <circle cx="150" cy="86" r="8" />
        <circle cx="194" cy="86" r="8" />
        <circle cx="238" cy="86" r="8" />
      </ContentLoader>
    </div>
  );
}

export default ProfileLoader;
