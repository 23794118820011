import React from 'react';
import { MainContainer } from '../../system/style/styleMain';
import ImgSpaceOne from '../../access/images/ImgSpaceOne.png';
import BackgroundBanner from '../../access/images/backgroundBanner.png';
import ImgSpaceTwo from '../../access/images/ImgSpaceTwo.png';
import ImgSpaceThree from '../../access/images/ImgSpaceThree.png';

const Main = () => (
  <MainContainer>
    <section
      style={{
        backgroundImage: `url("${BackgroundBanner}")`,
      }}
      className="banner"
    >
      <article
        className="textMain"
      >
        <h1>
          NOVA MARKETING DIGITAL
        </h1>
        <h3>
          {/* eslint-disable-next-line max-len */}
          Somos una agencia de marketing digital dedicada a potencializar el crecimiento de las empresas a través de estrategias digitales.
        </h3>
      </article>
      <article className="imgIcon">
        <img alt="Icon" src={ImgSpaceOne} />
      </article>
    </section>

    <section className="twoSection">
      <article className="imgIcon">
        <img alt="Icon" src={ImgSpaceTwo} />
      </article>

      <article
        className="textMain"
      >
        <h1>
          DISEÑO PUBLICITARIO Y REDES SOCIALES PARA EMPRESAS
        </h1>
        <h3>
          {/* eslint-disable-next-line max-len */}
          Creamos contenido con potencial de generar engagement y dar reconocimiento a tu marca, hacemos crecer tu marca a través de distintas estrategias en social media, tanto orgánicas como publicidad.
        </h3>
      </article>
    </section>

    <section className="twoSection">
      <article
        className="textMain"
      >
        <h1>
          BRANDING Y REBRANDING
        </h1>
        <h3>
          {/* eslint-disable-next-line max-len */}
          Ayudamos a construir paso a paso la identidad de la empresa y renovamos la identidad de la empresa por medio de estrategias para posicionar la marca en la mente del consumidor.
        </h3>
      </article>

      <article className="imgIcon">
        <img alt="Icon" src={ImgSpaceThree} />
      </article>
    </section>
  </MainContainer>
);
export default Main;
