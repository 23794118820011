import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavBar } from '../system/style/styleNavBar';
import LogoWhite from '../access/images/logoWhite.png';

const Navbar = () => (
  <NavBar>
    <img src={LogoWhite} alt="Logo" id="Logo" />
    <ul>
      <NavLink to="/main"> Inició </NavLink>
      <NavLink to="/we"> Nosotros </NavLink>
      <NavLink to="/contact"> Contacto </NavLink>
    </ul>
  </NavBar>
);

export default Navbar;
